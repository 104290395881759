import { useEffect, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
	asyncWithFFProvider,
	type FFProviderConfig,
	useFeatureFlags,
} from "@getbread/feature-flag-sdk-react";
import { FullScreenCircularProgress } from "@getbread/dough";
import type { CamelCase } from "type-fest";
import type { FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { DelayedRender } from "../../utils/useDelayedRender";
import { getConfig } from "../../utils/getConfig";

const __UNTYPED_MP_FLAGS = {
	/** @remarks Show banner for maintenance messaging */
	mpEnableMaintenanceNotification: "false",
};

type MemberPortalFlagKey = CamelCase<keyof typeof __UNTYPED_MP_FLAGS>;

export type MemberPortalFlags = Record<MemberPortalFlagKey, string>;

export const defaultFlags: MemberPortalFlags = __UNTYPED_MP_FLAGS;

const Noop: FCWithChildren = ({ children }) => <>{children}</>;

export const FeatureFlagProvider: FCWithChildren = ({ children }) => {
	const featureFlagConfig: FFProviderConfig = {
		apiOrigin: getConfig("apiurl"),
		clientSideId: getConfig("featureflagclientsideid"),
		context: {
			tenantId: getConfig("tenant"),
		},
		tag: "member-portal-frontend",
	};

	const [loading, setLoading] = useState(true);

	const FFProvider = useRef<React.ElementType>(Noop);

	const loadProvider = async () => {
		try {
			FFProvider.current = await asyncWithFFProvider(featureFlagConfig);
		} catch (e) {
			// if we can't connect to FF service the hook will work but all flags will be `undefined`
			FFProvider.current = Noop;
		}

		setLoading(false);
	};

	useEffect(() => {
		void loadProvider();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<DelayedRender delay={250}>
				<FullScreenCircularProgress />
			</DelayedRender>
		);
	}

	// eslint-disable-next-line react/jsx-pascal-case
	return <FFProvider.current>{children}</FFProvider.current>;
};

const convertToBooleanIfNeeded = <T extends string | undefined>(value: T): T | boolean => {
	if (value === "true") {
		return true;
	}
	if (value === "false") {
		return false;
	}
	return value;
};

export const useFlag = (key: MemberPortalFlagKey) => {
	const flags = useFeatureFlags();
	return convertToBooleanIfNeeded(flags[key]);
};
