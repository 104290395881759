import { useMediaQuery } from "@mui/material";
import { DesktopNav } from "./DesktopNav";
import { HamburgerNav } from "./HamburgerNav";

const Navigation: React.FC = () => {
	const isSmallScreen = useMediaQuery("(max-width: 775px)", {
		noSsr: true,
	});

	return isSmallScreen ? <HamburgerNav /> : <DesktopNav />;
};

export { Navigation };
