/* eslint-disable @typescript-eslint/no-unsafe-return */
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { styled } from "@mui/material/styles";
import type { FCWithChildren } from "../../api-client/resources/types/sharedTypes";

// see https://css-tricks.com/using-css-transitions-auto-dimensions/ for why max-height is animated instead of height
const SlideTop = styled(CSSTransition)`
	&.${(props) => `${props.classNames}-enter`} {
		max-height: 0;
		overflow: hidden;
	}
	&.${(props) => `${props.classNames}-enter-active`} {
		max-height: 1000px;
		transition: all ${(props) => props.timeout}ms ease-in;
	}
	&.${(props) => `${props.classNames?.toString()}-exit`} {
		overflow: hidden;
		max-height: 1000px;
	}
	&.${(props) => `${props.classNames?.toString()}-exit`}.${(props) =>
			`${props.classNames?.toString()}-exit-active`} {
		max-height: 0;
		transition: all ${(props) => props.timeout}ms ease-in;
	}
`;

type SlideInProps = {
	transitionOn: boolean;
	timeout?: number;
};

const SlideIn: FCWithChildren<SlideInProps> = ({ transitionOn, children, timeout = 250 }) => {
	return (
		<TransitionGroup>
			{transitionOn && (
				<SlideTop classNames="slide-in" timeout={timeout}>
					{children}
				</SlideTop>
			)}
		</TransitionGroup>
	);
};

export { SlideIn };
